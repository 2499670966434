export function convertToRupiah(number) {
  if (number) {
    var rupiah = "";

    var numberrev = number

      .toString()

      .split("")

      .reverse()

      .join("");

    for (var i = 0; i < numberrev.length; i++)
      if (i % 3 === 0) rupiah += numberrev.substr(i, 3) + ".";

    return (
      "Rp. " +
      rupiah

        .split("", rupiah.length - 1)

        .reverse()

        .join("")
    );
  } else {
    return number;
  }
}

export var Data_Config = {
  api_id: 12789006,
  api_hash: "3630f6df6b56de2bee4eab4001bc11e8",
  phone: +6282136180185,
  string_session:
    "1BQANOTEuMTA4LjU2LjIwMAG7TeDZRGpOS/hCKP+fBMnlhEGhtevEaUZF63ix9hn34a8/N487v83ZXA89WXoVlFzTcrjEGmqe/l7GsD007aCb8sglIHkkyS5sVl8cwzM5m63geKCSb9KpVxwhg8vZz43g3zmXgypm+GEZqCFz6PHKUElruAYjlhPgbxVbI/x5x/avA8XHasFxuNLVxUM43SSyOvqhKr9w7hgo0/1giOZzTof0/ahtA/0YFNC3wgPhnDvOi7v3TkBjOb7rrWld5p1Kgpjy958YMiX7GvuiE+tbgBV8YiWD0KAntuJYmm+DUKG9pKhvSoqt70Va257OajfNnz6cdvWqsoyXvNJLpngTgg==",
};

export var shuffle = (array) => {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    let index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter--;

    // And swap the last element with it
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
};

export var getRoundRobin = (index, array) => {
  const arrData = array;
  const after = arrData.slice(index + 1, arrData.length);
  const before = arrData.slice(0, index + 1);

  return after.concat(before);
};

export var SetSeccondsFlood = (dateFlood, dateNow) => {
  var dif = (dateNow.getTime() - dateFlood.getTime()) / 1000;
  return dif;
};

export var formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// export var formatNumber = (angka, prefix) => {
//   var number_string = angka.replace(/[^,\d]/g, "").toString(),
//     split = number_string.split(","),
//     sisa = split[0].length % 3,
//     rupiah = split[0].substr(0, sisa),
//     ribuan = split[0].substr(sisa).match(/\d{3}/gi);

//   if (ribuan) {
//     var separator = sisa ? "." : "";
//     rupiah += separator + ribuan.join(".");
//   }

//   rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
//   return prefix === undefined ? rupiah : rupiah ? "" + rupiah : "";
// };

export var formatNumber = (ctrl) => {
  var val = ctrl;

  val = val.replace(/,/g, "");
  ctrl = "";
  val += "";
  let x = val.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";

  var rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }

  return (ctrl = x1 + x2);
};

export var isNegative = (num) => {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
};

export function uuidRandomId() {
  var u = "",
    i = 0;
  while (i++ < 36) {
    var c = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"[i - 1],
      r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    u += c === "-" || c === "4" ? c : v.toString(16);
  }
  return u;
}

export function getPreviousDay(date = new Date(), day) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - day);

  return previous;
}

export function getEstimationProfit(buy, sell) {
  const dataV3 = [
    {
      posisi: 0,
      lots: 0.0,
      jml_lots: 0.0,
      price: 100,
      take_profit: 0.0,
    },
    {
      posisi: 1,
      lots: 0.01,
      jml_lots: 0.01,
      price: 100,
      take_profit: 0.5,
    },
    {
      posisi: 2,
      lots: 0.01,
      jml_lots: 0.02,
      price: 100,
      take_profit: 1.03,
    },
    {
      posisi: 3,
      lots: 0.01,
      jml_lots: 0.03,
      price: 100,
      take_profit: 1.58,
    },
    {
      posisi: 4,
      lots: 0.01,
      jml_lots: 0.04,
      price: 100,
      take_profit: 2.17,
    },
    {
      posisi: 5,
      lots: 0.01,
      jml_lots: 0.06,
      price: 100,
      take_profit: 2.79,
    },
    {
      posisi: 6,
      lots: 0.01,
      jml_lots: 0.07,
      price: 100,
      take_profit: 3.44,
    },
    {
      posisi: 7,
      lots: 0.01,
      jml_lots: 0.08,
      price: 100,
      take_profit: 4.13,
    },
    {
      posisi: 8,
      lots: 0.01,
      jml_lots: 0.1,
      price: 100,
      take_profit: 4.86,
    },
    {
      posisi: 9,
      lots: 0.02,
      jml_lots: 0.11,
      price: 100,
      take_profit: 5.63,
    },
    {
      posisi: 10,
      lots: 0.02,
      jml_lots: 0.13,
      price: 100,
      take_profit: 6.44,
    },
    {
      posisi: 11,
      lots: 0.02,
      jml_lots: 0.15,
      price: 100,
      take_profit: 7.29,
    },
    {
      posisi: 12,
      lots: 0.02,
      jml_lots: 0.16,
      price: 100,
      take_profit: 8.19,
    },
    {
      posisi: 13,
      lots: 0.02,
      jml_lots: 0.18,
      price: 100,
      take_profit: 9.14,
    },
    {
      posisi: 14,
      lots: 0.02,
      jml_lots: 0.2,
      price: 100,
      take_profit: 10.15,
    },
    {
      posisi: 15,
      lots: 0.02,
      jml_lots: 0.22,
      price: 100,
      take_profit: 11.2,
    },
    {
      posisi: 16,
      lots: 0.02,
      jml_lots: 0.25,
      price: 100,
      take_profit: 12.32,
    },
    {
      posisi: 17,
      lots: 0.02,
      jml_lots: 0.27,
      price: 100,
      take_profit: 13.5,
    },
    {
      posisi: 18,
      lots: 0.02,
      jml_lots: 0.29,
      price: 100,
      take_profit: 14.74,
    },
    {
      posisi: 19,
      lots: 0.03,
      jml_lots: 0.32,
      price: 100,
      take_profit: 16.05,
    },
    {
      posisi: 20,
      lots: 0.03,
      jml_lots: 0.35,
      price: 100,
      take_profit: 17.43,
    },
    {
      posisi: 21,
      lots: 0.03,
      jml_lots: 0.38,
      price: 100,
      take_profit: 18.89,
    },
    {
      posisi: 22,
      lots: 0.03,
      jml_lots: 0.41,
      price: 100,
      take_profit: 20.43,
    },
    {
      posisi: 23,
      lots: 0.03,
      jml_lots: 0.44,
      price: 100,
      take_profit: 22.06,
    },
    {
      posisi: 24,
      lots: 0.03,
      jml_lots: 0.48,
      price: 100,
      take_profit: 23.77,
    },
    {
      posisi: 25,
      lots: 0.04,
      jml_lots: 0.51,
      price: 100,
      take_profit: 25.58,
    },
    {
      posisi: 26,
      lots: 0.04,
      jml_lots: 0.55,
      price: 100,
      take_profit: 27.48,
    },
    {
      posisi: 27,
      lots: 0.04,
      jml_lots: 0.59,
      price: 100,
      take_profit: 29.49,
    },
    {
      posisi: 28,
      lots: 0.04,
      jml_lots: 0.63,
      price: 100,
      take_profit: 31.62,
    },
    {
      posisi: 29,
      lots: 0.04,
      jml_lots: 0.68,
      price: 100,
      take_profit: 33.86,
    },
    {
      posisi: 30,
      lots: 0.05,
      jml_lots: 0.72,
      price: 100,
      take_profit: 36.22,
    },
    {
      posisi: 31,
      lots: 0.05,
      jml_lots: 0.77,
      price: 100,
      take_profit: 38.71,
    },
    {
      posisi: 32,
      lots: 0.05,
      jml_lots: 0.83,
      price: 100,
      take_profit: 41.34,
    },
    {
      posisi: 33,
      lots: 0.06,
      jml_lots: 0.88,
      price: 100,
      take_profit: 44.11,
    },
    {
      posisi: 34,
      lots: 0.06,
      jml_lots: 0.94,
      price: 100,
      take_profit: 47.04,
    },
    {
      posisi: 35,
      lots: 0.06,
      jml_lots: 1,
      price: 100,
      take_profit: 50.13,
    },
    {
      posisi: 36,
      lots: 0.07,
      jml_lots: 1.07,
      price: 100,
      take_profit: 53.38,
    },
    {
      posisi: 37,
      lots: 0.07,
      jml_lots: 1.14,
      price: 100,
      take_profit: 56.82,
    },
    {
      posisi: 38,
      lots: 0.07,
      jml_lots: 1.21,
      price: 100,
      take_profit: 60.44,
    },
    {
      posisi: 39,
      lots: 0.08,
      jml_lots: 1.29,
      price: 100,
      take_profit: 64.27,
    },
    {
      posisi: 40,
      lots: 0.08,
      jml_lots: 1.37,
      price: 100,
      take_profit: 68.3,
    },
    {
      posisi: 41,
      lots: 0.09,
      jml_lots: 1.45,
      price: 100,
      take_profit: 72.56,
    },
    {
      posisi: 42,
      lots: 0.09,
      jml_lots: 1.54,
      price: 100,
      take_profit: 77.05,
    },
    {
      posisi: 43,
      lots: 0.09,
      jml_lots: 1.64,
      price: 100,
      take_profit: 81.79,
    },
    {
      posisi: 44,
      lots: 0.1,
      jml_lots: 1.74,
      price: 100,
      take_profit: 86.79,
    },
    {
      posisi: 45,
      lots: 0.11,
      jml_lots: 1.84,
      price: 100,
      take_profit: 92.06,
    },
    {
      posisi: 46,
      lots: 0.11,
      jml_lots: 1.95,
      price: 100,
      take_profit: 97.62,
    },
    {
      posisi: 47,
      lots: 0.12,
      jml_lots: 2.07,
      price: 100,
      take_profit: 103.49,
    },
    {
      posisi: 48,
      lots: 0.12,
      jml_lots: 2.19,
      price: 100,
      take_profit: 109.68,
    },
    {
      posisi: 49,
      lots: 0.13,
      jml_lots: 2.32,
      price: 100,
      take_profit: 116.22,
    },
    {
      posisi: 50,
      lots: 0.14,
      jml_lots: 2.46,
      price: 100,
      take_profit: 123.11,
    },
    {
      posisi: 51,
      lots: 0.15,
      jml_lots: 2.61,
      price: 100,
      take_profit: 130.38,
    },
    {
      posisi: 52,
      lots: 0.15,
      jml_lots: 2.76,
      price: 100,
      take_profit: 138.05,
    },
    {
      posisi: 53,
      lots: 0.16,
      jml_lots: 2.92,
      price: 100,
      take_profit: 146.14,
    },
    {
      posisi: 54,
      lots: 0.17,
      jml_lots: 3.09,
      price: 100,
      take_profit: 154.68,
    },
    {
      posisi: 55,
      lots: 0.18,
      jml_lots: 3.27,
      price: 100,
      take_profit: 163.69,
    },
    {
      posisi: 56,
      lots: 0.19,
      jml_lots: 3.46,
      price: 100,
      take_profit: 173.19,
    },
    {
      posisi: 57,
      lots: 0.2,
      jml_lots: 3.66,
      price: 100,
      take_profit: 183.22,
    },
    {
      posisi: 58,
      lots: 0.21,
      jml_lots: 3.88,
      price: 100,
      take_profit: 193.79,
    },
    {
      posisi: 59,
      lots: 0.22,
      jml_lots: 4.1,
      price: 100,
      take_profit: 204.95,
    },
    {
      posisi: 60,
      lots: 0.24,
      jml_lots: 4.33,
      price: 100,
      take_profit: 216.73,
    },
    {
      posisi: 61,
      lots: 0.25,
      jml_lots: 4.58,
      price: 100,
      take_profit: 229.15,
    },
    {
      posisi: 62,
      lots: 0.26,
      jml_lots: 4.84,
      price: 100,
      take_profit: 242.25,
    },
    {
      posisi: 63,
      lots: 0.28,
      jml_lots: 5.12,
      price: 100,
      take_profit: 256.07,
    },
    {
      posisi: 64,
      lots: 0.29,
      jml_lots: 5.41,
      price: 100,
      take_profit: 270.66,
    },
    {
      posisi: 65,
      lots: 0.31,
      jml_lots: 5.72,
      price: 100,
      take_profit: 286.04,
    },
    {
      posisi: 66,
      lots: 0.32,
      jml_lots: 6.05,
      price: 100,
      take_profit: 302.27,
    },
    {
      posisi: 67,
      lots: 0.34,
      jml_lots: 6.39,
      price: 100,
      take_profit: 319.4,
    },
    {
      posisi: 68,
      lots: 0.36,
      jml_lots: 6.75,
      price: 100,
      take_profit: 337.47,
    },
    {
      posisi: 69,
      lots: 0.38,
      jml_lots: 7.13,
      price: 100,
      take_profit: 356.53,
    },
    {
      posisi: 70,
      lots: 0.4,
      jml_lots: 7.53,
      price: 100,
      take_profit: 376.64,
    },
    {
      posisi: 71,
      lots: 0.42,
      jml_lots: 7.96,
      price: 100,
      take_profit: 397.85,
    },
    {
      posisi: 72,
      lots: 0.45,
      jml_lots: 8.4,
      price: 100,
      take_profit: 420.23,
    },
    {
      posisi: 73,
      lots: 0.47,
      jml_lots: 8.88,
      price: 100,
      take_profit: 443.85,
    },
    {
      posisi: 74,
      lots: 0.5,
      jml_lots: 9.38,
      price: 100,
      take_profit: 468.76,
    },
    {
      posisi: 75,
      lots: 0.53,
      jml_lots: 9.9,
      price: 100,
      take_profit: 495.04,
    },
    {
      posisi: 76,
      lots: 0.55,
      jml_lots: 10.46,
      price: 100,
      take_profit: 522.77,
    },
    {
      posisi: 77,
      lots: 0.59,
      jml_lots: 11.04,
      price: 100,
      take_profit: 552.02,
    },
    {
      posisi: 78,
      lots: 0.62,
      jml_lots: 11.66,
      price: 100,
      take_profit: 582.88,
    },
    {
      posisi: 79,
      lots: 0.65,
      jml_lots: 12.31,
      price: 100,
      take_profit: 615.44,
    },
    {
      posisi: 80,
      lots: 0.69,
      jml_lots: 13,
      price: 100,
      take_profit: 649.79,
    },
    {
      posisi: 81,
      lots: 0.72,
      jml_lots: 13.72,
      price: 100,
      take_profit: 686.02,
    },
    {
      posisi: 82,
      lots: 0.76,
      jml_lots: 14.49,
      price: 100,
      take_profit: 724.26,
    },
    {
      posisi: 83,
      lots: 0.81,
      jml_lots: 15.29,
      price: 100,
      take_profit: 764.59,
    },
    {
      posisi: 84,
      lots: 0.85,
      jml_lots: 16.14,
      price: 100,
      take_profit: 807.14,
    },
    {
      posisi: 85,
      lots: 0.9,
      jml_lots: 17.04,
      price: 100,
      take_profit: 852.03,
    },
    {
      posisi: 86,
      lots: 0.95,
      jml_lots: 17.99,
      price: 100,
      take_profit: 899.4,
    },
    {
      posisi: 87,
      lots: 1,
      jml_lots: 18.99,
      price: 100,
      take_profit: 949.36,
    },
    {
      posisi: 88,
      lots: 1.05,
      jml_lots: 20.04,
      price: 100,
      take_profit: 1002.08,
    },
    {
      posisi: 89,
      lots: 1.11,
      jml_lots: 21.15,
      price: 100,
      take_profit: 1057.69,
    },
    {
      posisi: 90,
      lots: 1.17,
      jml_lots: 22.33,
      price: 100,
      take_profit: 1116.37,
    },
    {
      posisi: 91,
      lots: 1.24,
      jml_lots: 23.57,
      price: 100,
      take_profit: 1178.27,
    },
    {
      posisi: 92,
      lots: 1.31,
      jml_lots: 24.87,
      price: 100,
      take_profit: 1243.57,
    },
    {
      posisi: 93,
      lots: 1.38,
      jml_lots: 26.25,
      price: 100,
      take_profit: 1312.47,
    },
    {
      posisi: 94,
      lots: 1.45,
      jml_lots: 27.7,
      price: 100,
      take_profit: 1385.15,
    },
    {
      posisi: 95,
      lots: 1.53,
      jml_lots: 29.24,
      price: 100,
      take_profit: 1461.84,
    },
    {
      posisi: 96,
      lots: 1.62,
      jml_lots: 30.85,
      price: 100,
      take_profit: 1542.74,
    },
    {
      posisi: 97,
      lots: 1.71,
      jml_lots: 32.56,
      price: 100,
      take_profit: 1628.09,
    },
    {
      posisi: 98,
      lots: 1.8,
      jml_lots: 34.36,
      price: 100,
      take_profit: 1718.13,
    },
    {
      posisi: 99,
      lots: 1.9,
      jml_lots: 36.26,
      price: 100,
      take_profit: 1813.13,
    },
    {
      posisi: 100,
      lots: 2,
      jml_lots: 38.27,
      price: 100,
      take_profit: 1913.35,
    },
    {
      posisi: 101,
      lots: 2.11,
      jml_lots: 40.38,
      price: 100,
      take_profit: 2019.09,
    },
    {
      posisi: 102,
      lots: 2.23,
      jml_lots: 42.61,
      price: 100,
      take_profit: 2130.64,
    },
    {
      posisi: 103,
      lots: 2.35,
      jml_lots: 44.97,
      price: 100,
      take_profit: 2248.32,
    },
    {
      posisi: 104,
      lots: 2.48,
      jml_lots: 47.45,
      price: 100,
      take_profit: 2372.48,
    },
    {
      posisi: 105,
      lots: 2.62,
      jml_lots: 50.07,
      price: 100,
      take_profit: 2503.46,
    },
    {
      posisi: 106,
      lots: 2.76,
      jml_lots: 52.83,
      price: 100,
      take_profit: 2641.65,
    },
    {
      posisi: 107,
      lots: 2.92,
      jml_lots: 55.75,
      price: 100,
      take_profit: 2787.45,
    },
    {
      posisi: 108,
      lots: 3.08,
      jml_lots: 58.83,
      price: 100,
      take_profit: 2941.26,
    },
    {
      posisi: 109,
      lots: 3.25,
      jml_lots: 62.07,
      price: 100,
      take_profit: 3103.52,
    },
    {
      posisi: 110,
      lots: 3.42,
      jml_lots: 65.49,
      price: 100,
      take_profit: 3274.72,
    },
    {
      posisi: 111,
      lots: 3.61,
      jml_lots: 69.11,
      price: 100,
      take_profit: 3455.33,
    },
    {
      posisi: 112,
      lots: 3.81,
      jml_lots: 72.92,
      price: 100,
      take_profit: 3645.87,
    },
    {
      posisi: 113,
      lots: 4.02,
      jml_lots: 76.94,
      price: 100,
      take_profit: 3846.89,
    },
    {
      posisi: 114,
      lots: 4.24,
      jml_lots: 81.18,
      price: 100,
      take_profit: 4058.97,
    },
    {
      posisi: 115,
      lots: 4.47,
      jml_lots: 85.65,
      price: 100,
      take_profit: 4282.72,
    },
    {
      posisi: 116,
      lots: 4.72,
      jml_lots: 90.38,
      price: 100,
      take_profit: 4518.77,
    },
    {
      posisi: 117,
      lots: 4.98,
      jml_lots: 95.36,
      price: 100,
      take_profit: 4767.8,
    },
    {
      posisi: 118,
      lots: 5.25,
      jml_lots: 100.61,
      price: 100,
      take_profit: 5030.53,
    },
    {
      posisi: 119,
      lots: 5.54,
      jml_lots: 106.15,
      price: 100,
      take_profit: 5307.71,
    },
    {
      posisi: 120,
      lots: 5.85,
      jml_lots: 112,
      price: 100,
      take_profit: 5600.13,
    },
    {
      posisi: 121,
      lots: 6.17,
      jml_lots: 118.17,
      price: 100,
      take_profit: 5908.64,
    },
    {
      posisi: 122,
      lots: 6.51,
      jml_lots: 124.68,
      price: 100,
      take_profit: 6234.11,
    },
    {
      posisi: 123,
      lots: 6.87,
      jml_lots: 131.55,
      price: 100,
      take_profit: 6577.49,
    },
    {
      posisi: 124,
      lots: 7.25,
      jml_lots: 138.79,
      price: 100,
      take_profit: 6939.75,
    },
    {
      posisi: 125,
      lots: 7.64,
      jml_lots: 146.44,
      price: 100,
      take_profit: 7321.94,
    },
    {
      posisi: 126,
      lots: 8.06,
      jml_lots: 154.5,
      price: 100,
      take_profit: 7725.14,
    },
    {
      posisi: 127,
      lots: 8.51,
      jml_lots: 163.01,
      price: 100,
      take_profit: 8150.52,
    },
    {
      posisi: 128,
      lots: 8.98,
      jml_lots: 171.99,
      price: 100,
      take_profit: 8599.3,
    },
    {
      posisi: 129,
      lots: 9.47,
      jml_lots: 181.46,
      price: 100,
      take_profit: 9072.77,
    },
    {
      posisi: 130,
      lots: 9.99,
      jml_lots: 191.45,
      price: 100,
      take_profit: 9572.27,
    },
    {
      posisi: 131,
      lots: 10.54,
      jml_lots: 201.98,
      price: 100,
      take_profit: 10099.24,
    },
    {
      posisi: 132,
      lots: 11.12,
      jml_lots: 213.1,
      price: 100,
      take_profit: 10655.2,
    },
    {
      posisi: 133,
      lots: 11.73,
      jml_lots: 224.83,
      price: 100,
      take_profit: 11241.74,
    },
    {
      posisi: 134,
      lots: 12.38,
      jml_lots: 237.21,
      price: 100,
      take_profit: 11860.53,
    },
    {
      posisi: 135,
      lots: 13.06,
      jml_lots: 250.27,
      price: 100,
      take_profit: 12513.36,
    },
    {
      posisi: 136,
      lots: 13.77,
      jml_lots: 264.04,
      price: 100,
      take_profit: 13202.1,
    },
    {
      posisi: 137,
      lots: 14.53,
      jml_lots: 278.57,
      price: 100,
      take_profit: 13928.71,
    },
    {
      posisi: 138,
      lots: 15.33,
      jml_lots: 293.91,
      price: 100,
      take_profit: 14695.29,
    },
    {
      posisi: 139,
      lots: 16.17,
      jml_lots: 310.08,
      price: 100,
      take_profit: 15504.03,
    },
    {
      posisi: 140,
      lots: 17.06,
      jml_lots: 327.15,
      price: 100,
      take_profit: 16357.25,
    },
    {
      posisi: 141,
      lots: 18,
      jml_lots: 345.15,
      price: 100,
      take_profit: 17257.4,
    },
    {
      posisi: 142,
      lots: 18.99,
      jml_lots: 364.14,
      price: 100,
      take_profit: 18207.06,
    },
    {
      posisi: 143,
      lots: 20.04,
      jml_lots: 384.18,
      price: 100,
      take_profit: 19208.95,
    },
    {
      posisi: 144,
      lots: 21.14,
      jml_lots: 405.32,
      price: 100,
      take_profit: 20265.94,
    },
    {
      posisi: 145,
      lots: 22.3,
      jml_lots: 427.62,
      price: 100,
      take_profit: 21381.07,
    },
    {
      posisi: 146,
      lots: 23.53,
      jml_lots: 451.15,
      price: 100,
      take_profit: 22557.52,
    },
    {
      posisi: 147,
      lots: 24.82,
      jml_lots: 475.97,
      price: 100,
      take_profit: 23798.69,
    },
    {
      posisi: 148,
      lots: 26.19,
      jml_lots: 502.16,
      price: 100,
      take_profit: 25108.12,
    },
    {
      posisi: 149,
      lots: 27.63,
      jml_lots: 529.79,
      price: 100,
      take_profit: 26489.56,
    },
    {
      posisi: 150,
      lots: 29.15,
      jml_lots: 558.94,
      price: 100,
      take_profit: 27946.99,
    },
    {
      posisi: 151,
      lots: 30.75,
      jml_lots: 589.69,
      price: 100,
      take_profit: 29484.57,
    },
    {
      posisi: 152,
      lots: 32.44,
      jml_lots: 622.13,
      price: 100,
      take_profit: 31106.72,
    },
    {
      posisi: 153,
      lots: 34.23,
      jml_lots: 656.36,
      price: 100,
      take_profit: 32818.09,
    },
    {
      posisi: 154,
      lots: 36.11,
      jml_lots: 692.47,
      price: 100,
      take_profit: 34623.59,
    },
    {
      posisi: 155,
      lots: 38.1,
      jml_lots: 730.57,
      price: 100,
      take_profit: 36528.39,
    },
    {
      posisi: 156,
      lots: 40.19,
      jml_lots: 770.76,
      price: 100,
      take_profit: 38537.95,
    },
    {
      posisi: 157,
      lots: 42.4,
      jml_lots: 813.16,
      price: 100,
      take_profit: 40658.03,
    },
    {
      posisi: 158,
      lots: 44.73,
      jml_lots: 857.89,
      price: 100,
      take_profit: 42894.73,
    },
    {
      posisi: 159,
      lots: 47.19,
      jml_lots: 905.09,
      price: 100,
      take_profit: 45254.44,
    },
    {
      posisi: 160,
      lots: 49.79,
      jml_lots: 954.88,
      price: 100,
      take_profit: 47743.93,
    },
    {
      posisi: 161,
      lots: 52.53,
      jml_lots: 1007.41,
      price: 100,
      take_profit: 50370.35,
    },
    {
      posisi: 162,
      lots: 55.42,
      jml_lots: 1062.82,
      price: 100,
      take_profit: 53141.22,
    },
    {
      posisi: 163,
      lots: 58.47,
      jml_lots: 1121.29,
      price: 100,
      take_profit: 56064.48,
    },
    {
      posisi: 164,
      lots: 61.68,
      jml_lots: 1182.97,
      price: 100,
      take_profit: 59148.53,
    },
    {
      posisi: 165,
      lots: 65.07,
      jml_lots: 1248.04,
      price: 100,
      take_profit: 62402.2,
    },
    {
      posisi: 166,
      lots: 68.65,
      jml_lots: 1316.7,
      price: 100,
      take_profit: 65834.82,
    },
    {
      posisi: 167,
      lots: 72.43,
      jml_lots: 1389.12,
      price: 100,
      take_profit: 69456.23,
    },
    {
      posisi: 168,
      lots: 76.41,
      jml_lots: 1465.54,
      price: 100,
      take_profit: 73276.83,
    },
    {
      posisi: 169,
      lots: 80.61,
      jml_lots: 1546.15,
      price: 100,
      take_profit: 77307.55,
    },
    {
      posisi: 170,
      lots: 85.05,
      jml_lots: 1631.2,
      price: 100,
      take_profit: 81559.97,
    },
    {
      posisi: 171,
      lots: 89.73,
      jml_lots: 1720.93,
      price: 100,
      take_profit: 86046.27,
    },
    {
      posisi: 172,
      lots: 94.66,
      jml_lots: 1815.59,
      price: 100,
      take_profit: 90779.31,
    },
    {
      posisi: 173,
      lots: 99.87,
      jml_lots: 1915.45,
      price: 100,
      take_profit: 95772.67,
    },
    {
      posisi: 174,
      lots: 105.36,
      jml_lots: 2020.81,
      price: 100,
      take_profit: 101040.67,
    },
    {
      posisi: 175,
      lots: 111.15,
      jml_lots: 2131.97,
      price: 100,
      take_profit: 106598.41,
    },
    {
      posisi: 176,
      lots: 117.27,
      jml_lots: 2249.24,
      price: 100,
      take_profit: 112461.82,
    },
    {
      posisi: 177,
      lots: 123.72,
      jml_lots: 2372.95,
      price: 100,
      take_profit: 118647.72,
    },
    {
      posisi: 178,
      lots: 130.52,
      jml_lots: 2503.48,
      price: 100,
      take_profit: 125173.84,
    },
    {
      posisi: 179,
      lots: 137.7,
      jml_lots: 2641.18,
      price: 100,
      take_profit: 132058.91,
    },
    {
      posisi: 180,
      lots: 145.27,
      jml_lots: 2786.45,
      price: 100,
      take_profit: 139322.65,
    },
    {
      posisi: 181,
      lots: 153.26,
      jml_lots: 2939.72,
      price: 100,
      take_profit: 146985.89,
    },
    {
      posisi: 182,
      lots: 161.69,
      jml_lots: 3101.41,
      price: 100,
      take_profit: 155070.61,
    },
    {
      posisi: 183,
      lots: 170.59,
      jml_lots: 3272,
      price: 100,
      take_profit: 163600,
    },
    {
      posisi: 184,
      lots: 179.97,
      jml_lots: 3451.97,
      price: 100,
      take_profit: 172598.5,
    },
    {
      posisi: 185,
      lots: 189.87,
      jml_lots: 3641.84,
      price: 100,
      take_profit: 182091.92,
    },
    {
      posisi: 186,
      lots: 200.31,
      jml_lots: 3842.15,
      price: 100,
      take_profit: 192107.47,
    },
    {
      posisi: 187,
      lots: 211.33,
      jml_lots: 4053.48,
      price: 100,
      take_profit: 202673.88,
    },
    {
      posisi: 188,
      lots: 222.95,
      jml_lots: 4276.43,
      price: 100,
      take_profit: 213821.45,
    },
    {
      posisi: 189,
      lots: 235.21,
      jml_lots: 4511.64,
      price: 100,
      take_profit: 225582.13,
    },
    {
      posisi: 190,
      lots: 248.15,
      jml_lots: 4759.79,
      price: 100,
      take_profit: 237989.64,
    },
    {
      posisi: 191,
      lots: 261.8,
      jml_lots: 5021.59,
      price: 100,
      take_profit: 251079.57,
    },
    {
      posisi: 192,
      lots: 276.2,
      jml_lots: 5297.79,
      price: 100,
      take_profit: 264889.45,
    },
    {
      posisi: 193,
      lots: 291.39,
      jml_lots: 5589.18,
      price: 100,
      take_profit: 279458.87,
    },
    {
      posisi: 194,
      lots: 307.41,
      jml_lots: 5896.59,
      price: 100,
      take_profit: 294829.61,
    },
    {
      posisi: 195,
      lots: 324.32,
      jml_lots: 6220.91,
      price: 100,
      take_profit: 311045.73,
    },
    {
      posisi: 196,
      lots: 342.16,
      jml_lots: 6563.08,
      price: 100,
      take_profit: 328153.75,
    },
    {
      posisi: 197,
      lots: 360.98,
      jml_lots: 6924.05,
      price: 100,
      take_profit: 346202.71,
    },
    {
      posisi: 198,
      lots: 380.83,
      jml_lots: 7304.89,
      price: 100,
      take_profit: 365244.36,
    },
    {
      posisi: 199,
      lots: 401.78,
      jml_lots: 7706.67,
      price: 100,
      take_profit: 385333.29,
    },
    {
      posisi: 200,
      lots: 423.88,
      jml_lots: 8130.54,
      price: 100,
      take_profit: 406527.13,
    },
  ];
  const buyPosition = dataV3.find((item) => item.posisi === buy);
  const sellPosition = dataV3.find((item) => item.posisi === sell);
  // console.log("buyPosition: ", buyPosition);
  // console.log("sellPosition: ", sellPosition);

  if (!buyPosition || !sellPosition) {
    return 0;
  }

  return {
    buy: buyPosition.take_profit,
    sell: sellPosition.take_profit,
  };
}

export function getEstimationProfitBTC(buy, sell) {
  const dataBTC = [
    { posisi: 0, take_profit: 0.0 },
    { posisi: 1, take_profit: 138.88 },
    { posisi: 2, take_profit: 141.02 },
    { posisi: 3, take_profit: 143.39 },
    { posisi: 4, take_profit: 146.02 },
    { posisi: 5, take_profit: 148.93 },
    { posisi: 6, take_profit: 152.16 },
    { posisi: 7, take_profit: 155.74 },
    { posisi: 8, take_profit: 159.71 },
    { posisi: 9, take_profit: 164.12 },
    { posisi: 10, take_profit: 169.0 },
    { posisi: 11, take_profit: 174.42 },
    { posisi: 12, take_profit: 180.42 },
    { posisi: 13, take_profit: 187.08 },
    { posisi: 14, take_profit: 194.46 },
    { posisi: 15, take_profit: 202.64 },
    { posisi: 16, take_profit: 211.72 },
    { posisi: 17, take_profit: 221.78 },
    { posisi: 18, take_profit: 232.93 },
    { posisi: 19, take_profit: 245.3 },
    { posisi: 20, take_profit: 259.02 },
    { posisi: 21, take_profit: 274.23 },
    { posisi: 22, take_profit: 291.09 },
    { posisi: 23, take_profit: 309.79 },
    { posisi: 24, take_profit: 330.52 },
    { posisi: 25, take_profit: 353.51 },
    { posisi: 26, take_profit: 379.0 },
    { posisi: 27, take_profit: 407.26 },
    { posisi: 28, take_profit: 438.6 },
    { posisi: 29, take_profit: 473.35 },
    { posisi: 30, take_profit: 511.88 },
    { posisi: 31, take_profit: 554.6 },
    { posisi: 32, take_profit: 601.96 },
    { posisi: 33, take_profit: 654.48 },
    { posisi: 34, take_profit: 712.72 },
    { posisi: 35, take_profit: 777.29 },
    { posisi: 36, take_profit: 848.89 },
    { posisi: 37, take_profit: 928.27 },
    { posisi: 38, take_profit: 1016.3 },
    { posisi: 39, take_profit: 1113.9 },
    { posisi: 40, take_profit: 1222.12 },
    { posisi: 41, take_profit: 1342.11 },
    { posisi: 42, take_profit: 1475.16 },
    { posisi: 43, take_profit: 1622.68 },
    { posisi: 44, take_profit: 1786.26 },
    { posisi: 45, take_profit: 1967.63 },
    { posisi: 46, take_profit: 2168.74 },
    { posisi: 47, take_profit: 2391.72 },
    { posisi: 48, take_profit: 2638.97 },
    { posisi: 49, take_profit: 2913.12 },
    { posisi: 50, take_profit: 3217.09 },
    { posisi: 51, take_profit: 3554.14 },
    { posisi: 52, take_profit: 3927.85 },
    { posisi: 53, take_profit: 4342.23 },
    { posisi: 54, take_profit: 4801.69 },
    { posisi: 55, take_profit: 5311.15 },
    { posisi: 56, take_profit: 5876.03 },
    { posisi: 57, take_profit: 6502.36 },
    { posisi: 58, take_profit: 7196.85 },
    { posisi: 59, take_profit: 7966.89 },
    { posisi: 60, take_profit: 8820.72 },
    { posisi: 61, take_profit: 9767.44 },
    { posisi: 62, take_profit: 10817.16 },
    { posisi: 63, take_profit: 11981.1 },
    { posisi: 64, take_profit: 13271.67 },
    { posisi: 65, take_profit: 14702.66 },
    { posisi: 66, take_profit: 16289.33 },
    { posisi: 67, take_profit: 18048.64 },
    { posisi: 68, take_profit: 19999.36 },
    { posisi: 69, take_profit: 22162.31 },
    { posisi: 70, take_profit: 24560.6 },
    { posisi: 71, take_profit: 27219.82 },
    { posisi: 72, take_profit: 30168.37 },
    { posisi: 73, take_profit: 33437.71 },
    { posisi: 74, take_profit: 37062.76 },
    { posisi: 75, take_profit: 41082.22 },
    { posisi: 76, take_profit: 45538.99 },
    { posisi: 77, take_profit: 50480.66 },
    { posisi: 78, take_profit: 55959.98 },
    { posisi: 79, take_profit: 62035.46 },
    { posisi: 80, take_profit: 68771.94 },
    { posisi: 81, take_profit: 76241.36 },
    { posisi: 82, take_profit: 84523.44 },
    { posisi: 83, take_profit: 93706.62 },
    { posisi: 84, take_profit: 103888.93 },
    { posisi: 85, take_profit: 115179.07 },
    { posisi: 86, take_profit: 127697.58 },
    { posisi: 87, take_profit: 141578.1 },
    { posisi: 88, take_profit: 156968.83 },
    { posisi: 89, take_profit: 174034.06 },
    { posisi: 90, take_profit: 192956.0 },
    { posisi: 91, take_profit: 213936.64 },
    { posisi: 92, take_profit: 237199.97 },
    { posisi: 93, take_profit: 262994.36 },
    { posisi: 94, take_profit: 291595.17 },
    { posisi: 95, take_profit: 323307.75 },
    { posisi: 96, take_profit: 358470.66 },
    { posisi: 97, take_profit: 397459.3 },
    { posisi: 98, take_profit: 440689.89 },
    { posisi: 99, take_profit: 488623.98 },
    { posisi: 100, take_profit: 541773.3 },
  ];
  const buyPosition = dataBTC.find((item) => item.posisi === buy);
  const sellPosition = dataBTC.find((item) => item.posisi === sell);

  if (!buyPosition || !sellPosition) {
    return 0;
  }

  return {
    buy: buyPosition.take_profit,
    sell: sellPosition.take_profit,
  };
}

export function getEstimationProfitGOLD(buy, sell) {
  const data = [
    { posisi: 0, take_profit: 0.0 },
    { posisi: 1, take_profit: 1.41 },
    { posisi: 2, take_profit: 3.02 },
    { posisi: 3, take_profit: 4.85 },
    { posisi: 4, take_profit: 6.93 },
    { posisi: 5, take_profit: 9.3 },
    { posisi: 6, take_profit: 11.99 },
    { posisi: 7, take_profit: 15.06 },
    { posisi: 8, take_profit: 18.55 },
    { posisi: 9, take_profit: 22.52 },
    { posisi: 10, take_profit: 27.05 },
    { posisi: 11, take_profit: 32.19 },
    { posisi: 12, take_profit: 38.04 },
    { posisi: 13, take_profit: 44.71 },
    { posisi: 14, take_profit: 52.29 },
    { posisi: 15, take_profit: 60.92 },
    { posisi: 16, take_profit: 70.74 },
    { posisi: 17, take_profit: 81.91 },
    { posisi: 18, take_profit: 94.63 },
    { posisi: 19, take_profit: 109.1 },
    { posisi: 20, take_profit: 125.56 },
    { posisi: 21, take_profit: 144.3 },
    { posisi: 22, take_profit: 165.63 },
    { posisi: 23, take_profit: 189.9 },
    { posisi: 24, take_profit: 217.52 },
    { posisi: 25, take_profit: 248.95 },
    { posisi: 26, take_profit: 284.71 },
    { posisi: 27, take_profit: 325.42 },
    { posisi: 28, take_profit: 371.74 },
    { posisi: 29, take_profit: 424.45 },
    { posisi: 30, take_profit: 484.43 },
    { posisi: 31, take_profit: 552.7 },
    { posisi: 32, take_profit: 630.38 },
    { posisi: 33, take_profit: 718.79 },
    { posisi: 34, take_profit: 819.39 },
    { posisi: 35, take_profit: 933.88 },
    { posisi: 36, take_profit: 1064.17 },
    { posisi: 37, take_profit: 1212.44 },
    { posisi: 38, take_profit: 1381.17 },
    { posisi: 39, take_profit: 1573.18 },
    { posisi: 40, take_profit: 1791.69 },
    { posisi: 41, take_profit: 2040.36 },
    { posisi: 42, take_profit: 2323.34 },
    { posisi: 43, take_profit: 2645.37 },
    { posisi: 44, take_profit: 3011.84 },
    { posisi: 45, take_profit: 3428.89 },
    { posisi: 46, take_profit: 3903.49 },
    { posisi: 47, take_profit: 4443.58 },
    { posisi: 48, take_profit: 5058.21 },
    { posisi: 49, take_profit: 5757.66 },
    { posisi: 50, take_profit: 6553.63 },
    { posisi: 51, take_profit: 7459.44 },
    { posisi: 52, take_profit: 8490.25 },
    { posisi: 53, take_profit: 9663.32 },
    { posisi: 54, take_profit: 10998.27 },
    { posisi: 55, take_profit: 12517.44 },
    { posisi: 56, take_profit: 14246.26 },
    { posisi: 57, take_profit: 16213.66 },
    { posisi: 58, take_profit: 18452.56 },
    { posisi: 59, take_profit: 21000.42 },
    { posisi: 60, take_profit: 23899.89 },
    { posisi: 61, take_profit: 27199.49 },
    { posisi: 62, take_profit: 30954.43 },
    { posisi: 63, take_profit: 35227.56 },
    { posisi: 64, take_profit: 40090.37 },
    { posisi: 65, take_profit: 45624.25 },
    { posisi: 66, take_profit: 51921.81 },
    { posisi: 67, take_profit: 59088.44 },
    { posisi: 68, take_profit: 67244.05 },
    { posisi: 69, take_profit: 76525.14 },
    { posisi: 70, take_profit: 87087.03 },
    { posisi: 71, take_profit: 99106.45 },
    { posisi: 72, take_profit: 112784.55 },
    { posisi: 73, take_profit: 128350.23 },
    { posisi: 74, take_profit: 146063.97 },
    { posisi: 75, take_profit: 166222.22 },
    { posisi: 76, take_profit: 189162.29 },
    { posisi: 77, take_profit: 215268.1 },
    { posisi: 78, take_profit: 244976.51 },
    { posisi: 79, take_profit: 278784.68 },
    { posisi: 80, take_profit: 317258.38 },
    { posisi: 81, take_profit: 361041.45 },
    { posisi: 82, take_profit: 410866.58 },
    { posisi: 83, take_profit: 467567.59 },
    { posisi: 84, take_profit: 532093.32 },
    { posisi: 85, take_profit: 605523.62 },
    { posisi: 86, take_profit: 689087.29 },
    { posisi: 87, take_profit: 784182.75 },
    { posisi: 88, take_profit: 892401.38 },
    { posisi: 89, take_profit: 1015554.18 },
    { posisi: 90, take_profit: 1155702.07 },
    { posisi: 91, take_profit: 1315190.37 },
    { posisi: 92, take_profit: 1496688.05 },
    { posisi: 93, take_profit: 1703232.41 },
    { posisi: 94, take_profit: 1938279.9 },
    { posisi: 95, take_profit: 2205763.93 },
    { posisi: 96, take_profit: 2510160.77 },
    { posisi: 97, take_profit: 2856564.37 },
    { posisi: 98, take_profit: 3250771.66 },
    { posisi: 99, take_profit: 3699379.56 },
    { posisi: 100, take_profit: 4209895.36 },
    { posisi: 101, take_profit: 4790862.33 },
    { posisi: 102, take_profit: 5452002.74 },
    { posisi: 103, take_profit: 6204380.53 },
    { posisi: 104, take_profit: 7060586.46 },
    { posisi: 105, take_profit: 8034948.8 },
    { posisi: 106, take_profit: 9143773.15 },
    { posisi: 107, take_profit: 10405615.25 },
    { posisi: 108, take_profit: 11841591.57 },
    { posisi: 109, take_profit: 13475732.62 },
    { posisi: 110, take_profit: 15335385.13 },
    { posisi: 111, take_profit: 17451669.69 },
    { posisi: 112, take_profit: 19860001.52 },
    { posisi: 113, take_profit: 22600683.15 },
    { posisi: 114, take_profit: 25719578.83 },
    { posisi: 115, take_profit: 29268882.12 },
    { posisi: 116, take_profit: 33307989.27 },
    { posisi: 117, take_profit: 37904493.2 },
    { posisi: 118, take_profit: 43135314.67 },
    { posisi: 119, take_profit: 49087989.51 },
    { posisi: 120, take_profit: 55862133.48 },
    { posisi: 121, take_profit: 63571109.31 },
    { posisi: 122, take_profit: 72343923.81 },
    { posisi: 123, take_profit: 82327386.7 },
    { posisi: 124, take_profit: 93688567.48 },
    { posisi: 125, take_profit: 106617591.21 },
    { posisi: 126, take_profit: 121330820.2 },
    { posisi: 127, take_profit: 138074474.81 },
    { posisi: 128, take_profit: 157128753.74 },
    { posisi: 129, take_profit: 178812523.17 },
    { posisi: 130, take_profit: 203488652.78 },
    { posisi: 131, take_profit: 231570088.27 },
  ];
  const buyPosition = data.find((item) => item.posisi === buy);
  const sellPosition = data.find((item) => item.posisi === sell);

  if (!buyPosition || !sellPosition) {
    return 0;
  }

  return {
    buy: buyPosition.take_profit,
    sell: sellPosition.take_profit,
  };
}
