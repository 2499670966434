import React, { Component, createRef } from "react";
import { MqttContext } from "../../Contexts/MqttProvider.js";
import {
  apiDashboardVps,
  apiAccountMt4_ByAgent,
  // apiAccountList,
  apiDailyBalance,
  apiDailyBalanceByAgent,
  apiDailyProfit,
  apiDailyProfitByAgent,
  apiMonthlyAllProfit,
  // apiDashboardTakeProfit,
  apiDashboardDailyProfitAdmin,
  apiDashboardDailyWdAdmin,
  apiDashboardDailyDepositAdmin,
  apiDashboardDailyEquityBalanceAdmin,
  apiDashboardSumDeposit,
  apiDashboardSumProfit,
  apiDashboardSumWithdrawal,
  apiDashboardTotalAccountActive,
  apiSlaveListMaster,
  apiMasterSlaveFixVpsMonitor,
  apiMasterSlaveFixVpsMonitorAuto,
  apiActionReset,
  apiActionRestart,
} from "../../Service/api";
import "./Dashboard.style.css";
import ReactTable from "react-table";
import {
  Box,
  Grid,
  Typography,
  Button,
  ButtonBase,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Paper,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
import {
  AccountBalanceWallet,
  MonetizationOn,
  Public,
  PublicOff,
  People,
  Assignment,
  TrendingUp,
  TrendingDown,
  Payments,
  Payment,
  Timeline,
  RemoveCircleOutline,
  ExpandMore,
  Handyman,
  RestartAlt,
  ResetTv,
} from "@mui/icons-material";
import {
  getPreviousDay,
  getEstimationProfit,
  getEstimationProfitBTC,
  getEstimationProfitGOLD,
} from "../../../global.js";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import $ from "jquery";
import "moment/locale/id";
import "./Dashboard.style.css";
moment.locale("id");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.mqttRef = React.createRef();
    this.reactTable = React.createRef();
    this.state = {
      community: props.community,
      tableData: [],
      tableDataSchedule: [],
      filter: "",
      total_account: 0,
      total_vps: 0,
      total_vps_on: 0,
      total_vps_off: 0,
      total_accounts: 0,
      total_account_active: 0,
      total_balance: 0,
      total_profit: 0,
      total_equity: 0,
      total_all_profit: 0,
      total_daily_profit: 0,
      total_daily_wd: 0,
      total_daily_deposit: 0,
      total_sum_profit: 0,
      total_sum_withdrawal: 0,
      total_sum_deposit: 0,
      total_sum_initial_deposit: 0,
      loginInfo: "",

      notifSlave: [],
      notifIndex: 0,
      checked: true,
      expanded: null,
      slaveMaster: [],
      filteredVps: [],
      clickedRowIndex: null,
      sortConfig: { key: "vps", direction: "ascending" },
      snackbarMessage: "",
      snackbarSeverity: "success",

      topicTradeData: "TradedataV2/+/+",
      activeTab: 0,
      tabs: 2,
      result: null,
      EURUSD: {
        tradeTick: null,
        priceBuy: null,
        priceSell: null,
        groupOrderTick: [],
        tradeBuy: [],
        tradeSell: [],
        estBuyProfit: 0,
        estSellProfit: 0,
        spread: 0,
        totalBuyOrder: 0,
        totalSellOrder: 0,
        buyTakeProfit: 0,
        sellTakeProfit: 0,
        buyPointDiff: 0,
        sellPointDiff: 0,
      },
      XAUUSDc: {
        tradeTick: null,
        priceBuy: null,
        priceSell: null,
        tradeBuy: [],
        tradeSell: [],
        groupOrderTick: [],
        estBuyProfit: 0,
        estSellProfit: 0,
        spread: 0,
        totalBuyOrder: 0,
        totalSellOrder: 0,
        buyTakeProfit: 0,
        sellTakeProfit: 0,
        buyPointDiff: 0,
        sellPointDiff: 0,
      },
      BTCUSDc: {
        tradeTick: null,
        priceBuy: null,
        priceSell: null,
        tradeBuy: [],
        tradeSell: [],
        groupOrderTick: [],
        estBuyProfit: 0,
        estSellProfit: 0,
        spread: 0,
        totalBuyOrder: 0,
        totalSellOrder: 0,
        buyTakeProfit: 0,
        sellTakeProfit: 0,
        buyPointDiff: 0,
        sellPointDiff: 0,
      },
    };
    this.tableScrollTop = 0;
    this.tableColumns = [
      {
        Header: "No.",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Last Timestamp",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_timestamp",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 150,
        Cell: (e) => (
          <Typography component="span" variant="body2">
            {dayjs(e.original.last_timestamp).format("DD/MM/YYYY HH:mm:ss")}
          </Typography>
        ),
      },
      {
        Header: "Status",
        headerStyle: { fontWeight: "bold" },
        accessor: "vps_status",
        style: { textAlign: "center" },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
              color:
                e.original.vps_status === "Offline" ||
                e.original.vps_status === "OFF"
                  ? "red"
                  : e.original.vps_status === "Busy"
                  ? "darkorange"
                  : "green",
              fontWeight: "600",
            }}
          >
            {e.original.vps_status}
          </Typography>
        ),
      },
      {
        Header: "VPS IP",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps_ip",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vps_ip}
          </Typography>
        ),
      },
      {
        Header: "VPS Alias",
        headerStyle: { fontWeight: "bold" },
        accessor: "vps_name",
        style: { textAlign: "center" },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vps_name}
          </Typography>
        ),
      },
      {
        Header: "Total Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "total",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.total}
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold" },
        accessor: "",
        style: { textAlign: "center" },
        width: 300,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                View
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#194d33",
              }}
              startIcon={<RestartAlt />}
              onClick={() => this.doRestart(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Restart
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff6900",
              }}
              startIcon={<ResetTv />}
              onClick={() => this.doReset(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Reset
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
    this.timer = null;
    this.masterSlaveTimer = null;
    this._isMounted = false;

    this.isSubscribed = false;

    this.activeTabRef = createRef();
    this.activeTabRef.current = 0;

    this.expandedRef = createRef();
    this.expandedRef.current = null;

    this.tradeDataRefs = {};
  }

  static contextType = MqttContext;

  componentDidMount() {
    this.checkMqttConnection();

    this._isMounted = true;
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadData();
    this.doDailyProfitAdmin();
    this.doDailyEquityBalance();
    this.doDailyWdAdmin();
    this.doDailyDepositAdmin();
    // this.doLoadDataAccount();
    this.doTotalAccountActive();
    this.doLoadSum();
    this.doDailyMasterSlaveNotif();

    this.timer = setInterval(() => {
      if (this._isMounted) {
        this.doDailyEquityBalance();
        this.doDailyWdAdmin();
        this.doDailyDepositAdmin();
      }
    }, 10000);

    this.masterSlaveTimer = setInterval(() => {
      if (this._isMounted) {
        this.doDailyMasterSlaveNotif();
      }
    }, 60000);

    let params = {
      agent: loginInfo.agent,
      infoAccount: loginInfo.infoAccount,
      logintype: loginInfo.logintype,
    };

    if (loginInfo.logintype === 4) {
      // this.doLoadDataAccount();
      this.doLoadDailyBalance();
      this.doLoadDailyProfit();
      this.doLoadTotalAllProfit(params);
    } else if (loginInfo.logintype === 2 || loginInfo.logintype === 3) {
      this.doLoadDataAccountByAgent(loginInfo.agent.id);
      this.doLoadDailyBalanceByAgent(loginInfo.agent.id);
      this.doLoadDailyProfitByAgent(loginInfo.agent.id);
      this.doLoadTotalAllProfit(params);
    }
  }

  componentWillUnmount() {
    const { unsubscribeFromTopic } = this.context;

    if (this.isSubscribed) {
      unsubscribeFromTopic("TradedataV2/+/+");
      this.isSubscribed = false;
    }

    this._isMounted = false;

    clearInterval(this.timer);
    clearInterval(this.masterSlaveTimer);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      messagesTradeDataEURUSD,
      messagesTradeChartEURUSD,
      messagesTradeDataBTCUSDc,
      messagesTradeChartBTCUSDc,
      messagesTradeChartXAUUSDc,
      messagesTradeDataXAUUSDc,
    } = this.context;

    return (
      nextContext.messagesTradeDataEURUSD !== messagesTradeDataEURUSD ||
      nextContext.messagesTradeChartEURUSD !== messagesTradeChartEURUSD ||
      nextContext.messagesTradeDataBTCUSDc !== messagesTradeDataBTCUSDc ||
      nextContext.messagesTradeChartBTCUSDc !== messagesTradeChartBTCUSDc ||
      nextContext.messagesTradeDataXAUUSDc !== messagesTradeDataXAUUSDc ||
      nextContext.messagesTradeChartXAUUSDc !== messagesTradeChartXAUUSDc
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      messagesTradeDataEURUSD,
      messagesTradeDataBTCUSDc,
      messagesTradeDataXAUUSDc,
      isConnected,
    } = this.context;

    if (prevProps.isConnected !== isConnected) {
      this.checkMqttConnection();
    }

    if (prevState.messagesTradeDataEURUSD !== messagesTradeDataEURUSD) {
      this.handleTradeDataUpdate(messagesTradeDataEURUSD, "EURUSD");
    }

    if (prevState.messagesTradeDataBTCUSDc !== messagesTradeDataBTCUSDc) {
      this.handleTradeDataUpdate(messagesTradeDataBTCUSDc, "BTCUSDc");
    }

    if (prevState.messagesTradeDataXAUUSDc !== messagesTradeDataXAUUSDc) {
      this.handleTradeDataUpdate(messagesTradeDataXAUUSDc, "XAUUSDc");
    }
  }

  checkMqttConnection() {
    const { isConnected, subscribeToTopic } = this.context;

    if (isConnected) {
      if (!this.isSubscribed) {
        console.log("MQTT is connected:", isConnected);
        subscribeToTopic("TradedataV2/+/+");
        this.isSubscribed = true;
        console.log("Successfully subscribed to TradedataV2/+/+");
      }
    } else {
      if (this.isSubscribed) {
        console.log("MQTT is not connected.");
        this.isSubscribed = false;
      }
    }
  }

  handleTradeDataUpdate(tradeData, currency) {
    if (tradeData && Array.isArray(tradeData.groupOrders)) {
      const groupOrderTick = tradeData.groupOrders;
      const tradeBuy = tradeData.groupOrders.filter((item) => item.type === 0);
      const tradeSell = tradeData.groupOrders.filter((item) => item.type === 1);

      const totalBuyOrder = tradeBuy.length > 0 ? tradeBuy[0].totalOrder : 0;
      const totalSellOrder = tradeSell.length > 0 ? tradeSell[0].totalOrder : 0;

      const buyTakeProfit = tradeBuy.length > 0 ? tradeBuy[0].takeProfit : 0;
      const sellTakeProfit = tradeSell.length > 0 ? tradeSell[0].takeProfit : 0;

      const buyPointDiff = tradeBuy.length > 0 ? tradeBuy[0].pointDiff : 0;
      const sellPointDiff = tradeSell.length > 0 ? tradeSell[0].pointDiff : 0;

      const estimationBuy = tradeBuy.length > 0 ? tradeBuy[0].totalOrder : 0;
      const estimationSell = tradeSell.length > 0 ? tradeSell[0].totalOrder : 0;

      const estimationProfit =
        currency === "EURUSD"
          ? getEstimationProfit(estimationBuy, estimationSell)
          : currency === "XAUUSDc"
          ? getEstimationProfitGOLD(estimationBuy, estimationSell)
          : getEstimationProfitBTC(estimationBuy, estimationSell);

      const ASK = tradeData.priceSell;
      const BID = tradeData.priceBuy;

      // console.table({ ASK, BID });

      const spread =
        currency === "EURUSD"
          ? Math.round((ASK - BID) * 100000)
          : currency === "XAUUSDc"
          ? Math.round((ASK - BID) * 100)
          : Math.round(ASK - BID);

      // console.log("spread: ", spread);

      this.setState((prevState) => {
        const newCurrencyState = {
          tradeTick: tradeData,
          groupOrderTick: groupOrderTick,
          priceBuy: BID,
          priceSell: ASK,
          tradeBuy,
          tradeSell,
          estBuyProfit: estimationProfit.buy,
          estSellProfit: estimationProfit.sell,
          spread,
          totalBuyOrder,
          totalSellOrder,
          buyTakeProfit,
          sellTakeProfit,
          buyPointDiff,
          sellPointDiff,
        };

        if (
          JSON.stringify(prevState[currency]) !==
          JSON.stringify(newCurrencyState)
        ) {
          return { [currency]: newCurrencyState };
        }
        return null;
      });
    }
  }

  doDailyMasterSlaveNotif = () => {
    if (this._isMounted) {
      apiSlaveListMaster()
        .then((res) => {
          const data = res.data;
          if (data.status === "OK") {
            const tempData = data.records;

            // console.log("tempData: ", tempData);

            const uniqueVps = {};

            const filteredVps = tempData.reduce((acc, master) => {
              master.infoSlave.forEach((slave) => {
                if (!uniqueVps[slave.vps_ip]) {
                  uniqueVps[slave.vps_ip] = true;
                  acc.push(slave);
                }
              });
              return acc;
            }, []);

            // console.log("filteredVps: ", filteredVps);

            if (tempData.length > 0) {
              this.setState({
                slaveMaster: tempData,
                filteredVps: filteredVps,
              });
            }
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  doDailyProfitAdmin = () => {
    apiDashboardDailyProfitAdmin()
      .then((res) => {
        // console.log("res: ", res);
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const profit = data.records[0].total_profit;
            const formattedProfit = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(profit);

            this.setState({
              total_daily_profit: formattedProfit,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  doDailyWdAdmin = () => {
    if (this._isMounted) {
      apiDashboardDailyWdAdmin()
        .then((res) => {
          // console.log("res: ", res);
          const data = res.data;
          if (data.status === "OK") {
            if (data.records.length > 0) {
              const profit = data.records[0].total_profit;
              const formattedWd = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(profit);

              this.setState({
                total_daily_wd: formattedWd,
              });
            }
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  doDailyDepositAdmin = () => {
    if (this._isMounted) {
      apiDashboardDailyDepositAdmin()
        .then((res) => {
          // console.log("res: ", res);
          const data = res.data;
          if (data.status === "OK") {
            if (data.records.length > 0) {
              const profit = data.records[0].total_profit;
              const formattedWd = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(profit);

              this.setState({
                total_daily_deposit: formattedWd,
              });
            }
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  doTotalAccountActive = () => {
    apiDashboardTotalAccountActive()
      .then((res) => {
        // console.log("res: ", res);
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            total_account_active: data.total_active_accounts,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  doLoadSum = () => {
    apiDashboardSumDeposit()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const sumDeposit = data.records[0].sum_deposit;
            const formattedSumDeposit = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(sumDeposit);

            this.setState({
              total_sum_deposit: formattedSumDeposit,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    apiDashboardSumProfit()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const sumProfit = data.records[0].sum_profit;
            const formattedSumProfit = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(sumProfit);

            this.setState({
              total_sum_profit: formattedSumProfit,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    apiDashboardSumWithdrawal()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const sumWithdrawal = data.records[0].sum_withdrawal;
            const formattedSumWithdrawal = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(sumWithdrawal);

            this.setState({
              total_sum_withdrawal: formattedSumWithdrawal,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    // apiDashboardSumInitialDeposit()
    //   .then((res) => {
    //     const data = res.data;
    //     if (data.status === "OK") {
    //       if (data.records.length > 0) {
    //         const sumInitialDeposit = data.records[0].sum_initial_deposit;
    //         const formattedSumInitialDeposit = new Intl.NumberFormat("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         }).format(sumInitialDeposit);

    //         this.setState({
    //           total_sum_initial_deposit: formattedSumInitialDeposit,
    //         });
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err: ", err);
    //   });
  };

  doDailyEquityBalance = () => {
    if (this._isMounted) {
      apiDashboardDailyEquityBalanceAdmin()
        .then((res) => {
          const data = res.data;
          if (data.status === "OK" && data.records.length > 0) {
            const equity = data.records[0].total_equity;
            const balance = data.records[0].total_balance;

            const formattedEquity = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(equity);

            const formattedBalance = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(balance);

            this.setState({
              total_equity: formattedEquity,
              total_balance: formattedBalance,
            });
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  sumArrayProperty = (data, propertyName) => {
    return data.reduce((sum, item) => sum + item[propertyName], 0);
  };

  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  // doLoadDataAccount = () => {
  //   apiAccountList()
  //     .then((res) => {
  //       let data = res.data;
  //       if (data.status === "OK") {
  //         if (data.records.length > 0) {
  //           var temp = data.records;

  //           if (this.state.loginInfo.logintype === 4) {
  //             const filteredData1 = temp.filter((item1) =>
  //               this.state.loginInfo.infoAccount.some(
  //                 (item2) => item2.account === item1.account_number
  //               )
  //             );

  //             const sumEquity = this.sumArrayProperty(filteredData1, "equity");

  //             const equityInUSD = sumEquity / 100;

  //             const formattedEquity = new Intl.NumberFormat("en-US", {
  //               style: "currency",
  //               currency: "USD",
  //             }).format(equityInUSD);

  //             this.setState({
  //               total_equity: formattedEquity,
  //               total_accounts: filteredData1.length,
  //             });
  //           } else {
  //             // const sumBalance = this.sumArrayProperty(temp, "balance");
  //             // const balanceInUSD = sumBalance / 100;
  //             // const formattedBalance = new Intl.NumberFormat("en-US", {
  //             //   style: "currency",
  //             //   currency: "USD",
  //             // }).format(balanceInUSD);
  //             // const sumEquity = this.sumArrayProperty(temp, "equity");
  //             // const equityInUSD = sumEquity / 100;
  //             // const formattedEquity = new Intl.NumberFormat("en-US", {
  //             //   style: "currency",
  //             //   currency: "USD",
  //             // }).format(equityInUSD);
  //             // const dataToPrint = [
  //             //   { balance: formattedBalance, equity: formattedEquity },
  //             // ];
  //             // console.table(dataToPrint);
  //           }
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  doLoadTotalAllProfit = (params) => {
    apiMonthlyAllProfit(params)
      .then((res) => {
        const data = res.data;
        if (data.status === "00") {
          const formattedTotalProfit = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.total_all_profit);

          this.setState({
            total_all_profit: formattedTotalProfit,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDataAccountByAgent = (agent_id) => {
    apiAccountMt4_ByAgent(agent_id)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = data.records;

            if (this.state.loginInfo.logintype === 3) {
              const filteredData1 = temp.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const sumEquity = this.sumArrayProperty(filteredData1, "equity");

              const equityInUSD = sumEquity / 100;

              const formattedEquity = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(equityInUSD);

              this.setState({
                total_equity: formattedEquity,
                total_accounts: filteredData1.length,
              });
            } else {
              const sumEquity = this.sumArrayProperty(temp, "equity");

              const equityInUSD = sumEquity / 100;

              const formattedEquity = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(equityInUSD);

              this.setState({
                total_equity: formattedEquity,
                total_accounts: temp.length,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyBalance = () => {
    apiDailyBalance()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyBalance = [];
            dataDailyBalance = data.records;

            const format1 = "YYYY-MM-DD";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);
            var day1Previous = moment(
              getPreviousDay(new Date(DateTimeNow), 1)
            ).format(format1);
            // var day2Previous = moment(
            //   getPreviousDay(new Date(DateTimeNow), 2)
            // ).format(format1);

            let arrayCurrentDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === DateTimeNow
            );

            let arrayPreviousDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === day1Previous
            );

            // let arrayPreviousTwoDate = dataDailyBalance.filter(
            //   (obj) => obj.last_update_date === day2Previous
            // );

            for (var a = 0; a < arrayCurrentDate.length; a++) {
              for (var b = 0; b < arrayPreviousDate.length; b++) {
                if (
                  arrayCurrentDate[a].account_number ===
                  arrayPreviousDate[b].account_number
                ) {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  } else {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance -
                      arrayPreviousDate[b].balance;
                  }
                } else {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  }
                }
              }
            }

            const dataArr = arrayCurrentDate;

            if (this.state.loginInfo.logintype === 4) {
              const filterDataDaily = dataArr.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const sumBalance = this.sumArrayProperty(
                filterDataDaily,
                "balance"
              );

              const balanceInUSD = sumBalance / 100;

              const formattedBalance = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(balanceInUSD);

              this.setState({
                total_balance: formattedBalance,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyBalanceByAgent = (agent_id) => {
    apiDailyBalanceByAgent(agent_id)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyBalance = [];
            dataDailyBalance = data.records;

            const format1 = "YYYY-MM-DD";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);
            var day1Previous = moment(
              getPreviousDay(new Date(DateTimeNow), 1)
            ).format(format1);
            // var day2Previous = moment(
            //   getPreviousDay(new Date(DateTimeNow), 2)
            // ).format(format1);

            let arrayCurrentDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === DateTimeNow
            );

            let arrayPreviousDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === day1Previous
            );

            // let arrayPreviousTwoDate = dataDailyBalance.filter(
            //   (obj) => obj.last_update_date === day2Previous
            // );

            for (var a = 0; a < arrayCurrentDate.length; a++) {
              for (var b = 0; b < arrayPreviousDate.length; b++) {
                if (
                  arrayCurrentDate[a].account_number ===
                  arrayPreviousDate[b].account_number
                ) {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  } else {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance -
                      arrayPreviousDate[b].balance;
                  }
                } else {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  }
                }
              }
            }

            const dataArr = arrayCurrentDate;

            if (this.state.loginInfo.logintype === 3) {
              const filterDataDaily = dataArr.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const sumBalance = this.sumArrayProperty(
                filterDataDaily,
                "balance"
              );

              const balanceInUSD = sumBalance / 100;

              const formattedBalance = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(balanceInUSD);

              this.setState({
                total_balance: formattedBalance,
              });
            } else {
              const sumBalance = this.sumArrayProperty(dataArr, "balance");

              const balanceInUSD = sumBalance / 100;

              const formattedBalance = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(balanceInUSD);

              this.setState({
                total_balance: formattedBalance,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyProfit = () => {
    apiDailyProfit()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyProfit = [];
            dataDailyProfit = data.records;

            if (this.state.loginInfo.logintype === 4) {
              const filterDataDaily = dataDailyProfit.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const sumProfit = this.sumArrayProperty(
                filterDataDaily,
                "profit"
              );

              const profitInUSD = sumProfit / 100;

              const formattedProfit = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(profitInUSD);

              this.setState({
                total_profit: formattedProfit,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyProfitByAgent = (agent_id) => {
    apiDailyProfitByAgent(agent_id)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyProfit = [];
            dataDailyProfit = data.records;

            if (this.state.loginInfo.logintype === 3) {
              const filterDataDaily = dataDailyProfit.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const sumProfit = this.sumArrayProperty(
                filterDataDaily,
                "profit"
              );

              const profitInUSD = sumProfit / 100;

              const formattedProfit = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(profitInUSD);

              this.setState({
                total_profit: formattedProfit,
              });
            } else {
              const sumProfit = this.sumArrayProperty(
                dataDailyProfit,
                "profit"
              );

              const profitInUSD = sumProfit / 100;

              const formattedProfit = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(profitInUSD);

              this.setState({
                total_profit: formattedProfit,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadData = () => {
    if (this.state.tableData.length === 0) {
      this.props.doLoading();
    }
    apiDashboardVps()
      .then((res) => {
        if (this.state.tableData.length === 0) {
          this.props.doLoading();
        }
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            temp[i].total = temp[i].account_list.length;
          }

          function isRecentUpdate(lastTimestamp) {
            let now = new Date();
            let lastUpdate = new Date(lastTimestamp);
            let difference = now - lastUpdate;
            let differenceInMinutes = difference / (1000 * 60);
            return differenceInMinutes < 5;
          }

          temp.forEach((item) => {
            if (!isRecentUpdate(item.last_timestamp)) {
              item.vps_status =
                item.flag_auto === 1 ? item.vps_status : "Offline";
            } else {
              item.vps_status =
                item.flag_auto === 1 ? item.vps_status : "Online";
            }
          });

          let total_vps_on = temp.filter((obj) => obj.vps_status !== "Offline");
          let total_vps_off = temp.filter(
            (obj) => obj.vps_status === "Offline"
          );

          this.setState({
            tableData: temp,
            total_vps: temp.length,
            total_vps_on: total_vps_on.length,
            total_vps_off: total_vps_off.length,
            total_account: data.total_account,
          });
          // timer = setTimeout(() => this.doLoadData(), 30000);
        }
      })
      .catch((err) => {
        if (this.state.tableData.length === 0) {
          this.props.doLoading();
        }
        console.log(err);
      });
  };

  doRowEdit = (row) => {
    this.props.history.push("/panel/vps-detail/" + row.code_vps);
  };

  doRestart = (data) => {
    const item = data;

    let paramAutoRestart = {
      vps_id: item.vps_id,
      vps: item.vps_name,
      action: "restart",
      client_id: this.state.loginInfo.phoneno,
    };

    // console.log("paramAutoRestart: ", paramAutoRestart);

    this.props.doLoading();
    apiActionRestart(paramAutoRestart)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "success") {
          this.showSnackbar(`Sucessfully restart ${item.vps_name}`, "success");
        } else {
          this.showSnackbar(data.message, "error");
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.error(err);
      });
  };

  doReset = (data) => {
    const item = data;
    // console.log("item: ", item);

    let paramAutoReset = {
      vps_id: item.vps_id,
      vps: item.vps_name,
      action: "reset",
      client_id: this.state.loginInfo.phoneno,
    };

    // console.log("paramAutoReset: ", paramAutoReset);

    this.props.doLoading();
    apiActionReset(paramAutoReset)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "success") {
          this.showSnackbar(`Sucessfully reset ${item.vps}`, "success");
        } else {
          this.showSnackbar(data.message, "error");
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.error(err);
      });
  };

  handleChangeAccordion = (expanded) => {
    this.expandedRef.current =
      expanded === this.expandedRef.current ? null : expanded;
    this.forceUpdate();
  };

  showSnackbar = (message, severity) => {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
      openSnackbar: true,
      clickedRowIndex: null,
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnackbar: false });
  };

  handleRowClick = (item, index, data) => {
    this.setState({
      clickedRowIndex: index,
    });

    // console.log(data);
    // console.log(item);

    if (item.flag_auto === 1) {
      let paramFixAuto = {
        vps_id: item.vps_id,
        vps: item.vps,
        action: "fix",
        client_id: this.state.loginInfo.phoneno,
      };

      this.props.doLoading();
      apiMasterSlaveFixVpsMonitorAuto(paramFixAuto)
        .then((res) => {
          this.props.doLoading();
          const data = res.data;
          if (data.status === "success") {
            this.showSnackbar(`Sucessfully fixed VPS ${item.vps}`, "success");
          } else {
            this.showSnackbar(data.message, "error");
          }
        })
        .catch((err) => {
          this.props.doLoading();
          console.error(err);
        });
    } else {
      let param = {
        vps: item.vps_ip,
        vps_name: item.vps,
        accountMaster: data.account_number,
      };

      this.props.doLoading();
      apiMasterSlaveFixVpsMonitor(param)
        .then((res) => {
          this.props.doLoading();
          let data = res.data;
          if (data.status === 0) {
            this.showSnackbar(data.message, "error");
          } else {
            this.showSnackbar(`Sucessfully fixed VPS ${item.vps}`, "success");
            this.doDailyMasterSlaveNotif();
          }
        })
        .catch((err) => {
          this.props.doLoading();
          console.log(err);
        });
    }
  };

  doFixAll = async (data) => {
    const uniqueVPS = new Set();
    const closeVpsData = data.filter((item) => {
      if (!uniqueVPS.has(item.vps)) {
        uniqueVPS.add(item.vps);
        return true;
      }
      return false;
    });

    try {
      this.props.doLoading();

      const promises = closeVpsData.map(async (item) => {
        try {
          if (item.flag_auto === 1) {
            const paramFixAuto = {
              vps_id: item.vps_id,
              vps: item.vps,
              action: "fix",
              client_id: this.state.loginInfo.phoneno,
            };
            const { data } = await apiMasterSlaveFixVpsMonitorAuto(
              paramFixAuto
            );

            if (data.status === "success") {
              this.showSnackbar(
                `Successfully fixed VPS ${item.vps}`,
                "success"
              );
            } else {
              this.showSnackbar(data.message, "error");
            }
          } else {
            const param = {
              vps: item.vps_ip,
              vps_name: item.vps,
              accountMaster: item.account_number,
            };

            const { data } = await apiMasterSlaveFixVpsMonitor(param);

            if (data.status === 0) {
              this.showSnackbar(data.message, "error");
            } else {
              this.showSnackbar(
                `Successfully fixed VPS ${item.vps}`,
                "success"
              );
            }
          }
        } catch (err) {
          console.error(`Error fixing vps ${item.vps}:`, err);
          this.showSnackbar(`Error fixing vps ${item.vps}`, "error");
        }
      });

      await Promise.all(promises);

      this.props.doLoading();
      this.doDailyMasterSlaveNotif();
    } catch (err) {
      console.error("Error processing items:", err);
      this.showSnackbar("An unexpected error occurred", "error");

      this.props.doLoading();
    }
  };

  renderTableVps = (data) => {
    const dataList = data.infoSlave;
    const dataVps = this.state.filteredVps;
    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: "16px" }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#1273DE",
            }}
            startIcon={<Handyman />}
            onClick={() => this.doFixAll(dataVps)}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 14,
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Fix All
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: "16px" }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: "#cccccc" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>VPS</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Account</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Buy</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sell</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => this.handleRowClick(item, index, data)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        this.state.clickedRowIndex === index
                          ? "#d0e0f0"
                          : "inherit",
                    }}
                  >
                    <TableCell>{item.vps}</TableCell>
                    <TableCell>{item.account_number}</TableCell>
                    <TableCell>{item.buy}</TableCell>
                    <TableCell>{item.sell}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  };

  handleTabClick = (index) => {
    if (this.activeTabRef.current !== index) {
      this.activeTabRef.current = index;
      this.forceUpdate();
    }
  };

  render() {
    const {
      EURUSD,
      BTCUSDc,
      XAUUSDc,
      slaveMaster,
      filteredVps,
      clickedRowIndex,
      total_vps_on,
      total_vps_off,
      total_account,
      total_account_active,
      total_balance,
      total_equity,
      total_daily_profit,
      total_sum_profit,
      total_daily_deposit,
      total_sum_deposit,
      total_daily_wd,
      total_sum_withdrawal,
      tableData,
      openSnackbar,
      snackbarSeverity,
      snackbarMessage,
    } = this.state;

    const link_vps = () => this.props.history.push("/panel/vps");
    const link_accounts = () => this.props.history.push("/panel/accounts");
    const link_daily_profit = () =>
      this.props.history.push("/panel/daily-profit");
    const link_daily_deposit = () =>
      this.props.history.push("/panel/daily-deposit");
    const link_daily_withdrawal = () =>
      this.props.history.push("/panel/daily-withdrawal");

    const fontSize = window.innerWidth < 600 ? 14 : 18;

    return (
      <>
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <div className="tabs-container">
            <div className="tabs-header">
              <TabButton
                label="EURUSD"
                isActive={this.activeTabRef.current === 0}
                onClick={() => this.handleTabClick(0)}
              />
              <TabButton
                label="XAUUSDc"
                isActive={this.activeTabRef.current === 1}
                onClick={() => this.handleTabClick(1)}
              />
              <TabButton
                label="BTCUSDc"
                isActive={this.activeTabRef.current === 2}
                onClick={() => this.handleTabClick(2)}
              />
            </div>
            {this.activeTabRef.current === 0 && (
              <TabContent currency="EURUSD" data={EURUSD} />
            )}
            {this.activeTabRef.current === 1 && (
              <TabContent currency="XAUUSDc" data={XAUUSDc} />
            )}
            {this.activeTabRef.current === 2 && (
              <TabContent currency="BTCUSDc" data={BTCUSDc} />
            )}
          </div>
          {slaveMaster.length > 0
            ? slaveMaster.map((obj) => {
                return (
                  <Accordion
                    key={obj.account_number}
                    onChange={() =>
                      this.handleChangeAccordion(obj.account_number)
                    }
                    expanded={this.expandedRef.current === obj.account_number}
                    style={{
                      marginBottom: "16px",
                      backgroundColor: "#1f324b",
                      borderRadius: 6,
                      color: "white",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore style={{ color: "white" }} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        style={{
                          fontSize: fontSize,
                          fontWeight: "bold",
                        }}
                      >
                        Total ada {filteredVps.length} VPS yang beda Buy/Sell
                        lebih dari 5
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableListSlave
                        data={obj}
                        filterVps={filteredVps}
                        clickedRowIndex={clickedRowIndex}
                        handleRowClick={this.handleRowClick}
                        doFixAll={this.doFixAll}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : null}
          <CardBoxInfo
            total_vps_on={total_vps_on}
            total_vps_off={total_vps_off}
            total_account={total_account}
            total_account_active={total_account_active}
            total_balance={total_balance}
            total_equity={total_equity}
            total_daily_profit={total_daily_profit}
            total_sum_profit={total_sum_profit}
            total_daily_deposit={total_daily_deposit}
            total_sum_deposit={total_sum_deposit}
            total_daily_wd={total_daily_wd}
            total_sum_withdrawal={total_sum_withdrawal}
            link_vps={link_vps}
            link_accounts={link_accounts}
            link_daily_profit={link_daily_profit}
            link_daily_deposit={link_daily_deposit}
            link_daily_withdrawal={link_daily_withdrawal}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <TableDataVps
            tableData={tableData}
            tableColumns={this.tableColumns}
          />
        </Box>
        <AlertInfo
          openSnackbar={openSnackbar}
          snackbarSeverity={snackbarSeverity}
          snackbarMessage={snackbarMessage}
          handleCloseSnackbar={this.handleCloseSnackbar}
        />
      </>
    );
  }
}

const TabButton = React.memo(({ label, isActive, onClick }) => (
  <button
    className={`tab-button ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {label}
  </button>
));

const TabContent = React.memo(({ data }) => {
  const fontSize = window.innerWidth < 600 ? 14 : 18;

  if (!data) return null;

  return (
    <Grid container spacing={2} style={{ marginBottom: "16px" }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Paper
          style={{
            backgroundColor: "#1f324b",
            padding: 16,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Typography
                component="span"
                variant="h1"
                style={{
                  fontSize: fontSize,
                  display: "inline",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Buy:{" "}
                <span
                  style={{
                    color:
                      data.totalBuyOrder <= 99
                        ? "#00ff0b"
                        : data.totalBuyOrder >= 100 && data.totalBuyOrder <= 120
                        ? "orange"
                        : "#ff1100",
                    marginLeft: "2px",
                    marginRight: "2px",
                  }}
                >
                  {data.totalBuyOrder} {"  "}
                </span>{" "}
                <span>
                  (
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(data.estBuyProfit / 100)}
                  )
                </span>{" "}
                - TP {data.buyTakeProfit}{" "}
                <span
                  style={{
                    // marginLeft: 10,
                    marginLeft: "6px",
                    marginRight: "6px",
                    color:
                      data.buyPointDiff >= 200
                        ? "#ff1100"
                        : data.buyPointDiff >= 51 && data.buyPointDiff <= 199
                        ? "orange"
                        : "#00ff0b",
                  }}
                >
                  ({data.buyPointDiff} Point)
                </span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                component="span"
                variant="h1"
                style={{
                  fontSize: fontSize,
                  display: "initial",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Sell:{" "}
                <span
                  style={{
                    color:
                      data.totalSellOrder <= 99
                        ? "#00ff0b"
                        : data.totalSellOrder >= 100 &&
                          data.totalSellOrder <= 120
                        ? "orange"
                        : "#ff1100",
                    marginLeft: "2px",
                    marginRight: "2px",
                  }}
                >
                  {data.totalSellOrder} {"  "}
                </span>{" "}
                <span>
                  (
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(data.estSellProfit / 100)}
                  )
                </span>{" "}
                - TP {data.sellTakeProfit}{" "}
                <span
                  style={{
                    marginLeft: 10,
                    color:
                      data.sellPointDiff >= 200
                        ? "#ff1100"
                        : data.sellPointDiff >= 51 && data.sellPointDiff <= 199
                        ? "orange"
                        : "#00ff0b",
                  }}
                >
                  ({data.sellPointDiff} Point)
                </span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                component="span"
                variant="h1"
                style={{
                  fontSize: fontSize,
                  display: "inline",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Spread:
                <span
                  style={{
                    marginLeft: 10,
                    color: "#7bd5fd",
                  }}
                >
                  {data.spread} Point
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
});

const TableListSlave = React.memo(
  ({ data, filterVps, clickedRowIndex, handleRowClick, doFixAll }) => {
    const dataList = data.infoSlave;
    const dataVps = filterVps;

    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: "16px" }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#1273DE",
            }}
            startIcon={<Handyman />}
            onClick={() => doFixAll(dataVps)}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 14,
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Fix All
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: "16px" }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: "#cccccc" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>VPS</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Account</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Buy</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sell</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(item, index, data)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        clickedRowIndex === index ? "#d0e0f0" : "inherit",
                    }}
                  >
                    <TableCell>{item.vps}</TableCell>
                    <TableCell>{item.account_number}</TableCell>
                    <TableCell>{item.buy}</TableCell>
                    <TableCell>{item.sell}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  }
);

const CardBoxInfo = ({
  total_vps_on,
  total_vps_off,
  total_account,
  total_account_active,
  total_balance,
  total_equity,
  total_daily_profit,
  total_sum_profit,
  total_daily_deposit,
  total_sum_deposit,
  total_daily_wd,
  total_sum_withdrawal,
  link_vps,
  link_accounts,
  link_daily_profit,
  link_daily_deposit,
  link_daily_withdrawal,
}) => (
  <>
    <Grid Grid container spacing={2} mb={3}>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_vps}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <Public
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_vps}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_vps_on}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Total VPS ON
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_vps}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <PublicOff
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_vps}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_vps_off}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Total VPS OFF
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_accounts}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <People
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_accounts}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_account}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Total Account
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_accounts}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <People
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              button
              onClick={link_accounts}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_account_active}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Active Account
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
    </Grid>
    <Grid Grid container spacing={2} mb={3}>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_accounts}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <AccountBalanceWallet
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_accounts}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_balance}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Daily Balance
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_accounts}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <MonetizationOn
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_accounts}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_equity}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Daily Equity
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_daily_profit}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <TrendingUp
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_daily_profit}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_daily_profit}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Daily Profit
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <Timeline
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_sum_profit}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Total Profit
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
    </Grid>
    <Grid Grid container spacing={2} mb={3}>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_daily_deposit}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <Payment
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_daily_deposit}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_daily_deposit}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Daily Deposit
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <Payments
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_sum_deposit}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Total Deposit
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
              onClick={link_daily_withdrawal}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <TrendingDown
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
              onClick={link_daily_withdrawal}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_daily_wd}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Daily Withdrawal
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={3} sx={{ textAlign: "center" }}>
        <div className="dashboard-panel">
          <List>
            <ListItem
              alignItems="center"
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
            >
              <ListItemAvatar
                style={{
                  margin: 0,
                  backgroundColor: "#59C9B6",
                  borderRadius: 6,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonBase style={{ height: 80, width: 100 }}>
                  <RemoveCircleOutline
                    style={{
                      margin: "auto",
                      display: "block",
                      color: "#fff",
                      height: "100%",
                      width: "100%",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                </ButtonBase>
              </ListItemAvatar>
            </ListItem>
            <ListItem
              style={{
                padding: 0,
              }}
              alignItems="flex-start"
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 6,
                        fontSize: 19,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {total_sum_withdrawal}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h1"
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      Total Withdrawal
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid>
    </Grid>
  </>
);
const TableDataVps = React.memo(({ tableData, tableColumns }) => (
  <div className="box-container">
    <ReactTable
      data={tableData}
      columns={tableColumns}
      style={{ backgroundColor: "#f2f2f2" }}
      filterable
      defaultFilterMethod={(filter, row) => {
        const cellValue = String(row[filter.id]).toLowerCase();
        return cellValue.includes(filter.value.toLowerCase());
      }}
      defaultPageSize={500}
      showPageJump={false}
      showPageSizeOptions={false}
      showPagination={false}
      getTableProps={() => {
        return {
          onScroll: (e) => {
            let left = e.target.scrollLeft > 0 ? e.target.scrollLeft : 0;
            $(".ReactTable .rt-tr .frozen").css({ left: left });
          },
        };
      }}
    />
  </div>
));

const AlertInfo = ({
  openSnackbar,
  snackbarSeverity,
  snackbarMessage,
  handleCloseSnackbar,
}) => (
  <Snackbar
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    open={openSnackbar}
    autoHideDuration={6000}
    onClose={handleCloseSnackbar}
    style={{ marginTop: "80px" }}
  >
    <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
      {snackbarMessage}
    </MuiAlert>
  </Snackbar>
);

export default Dashboard;
